<template>
  <div class="main-content">
    <img :src="require('../../assets/wallet-bg.png')" class="bg" />
    <div class="header-wrapper">
      <viewHeader/>
      
    </div> 
    <div class="wrapper">
      <div class="progress-bars">
        <div class="progress-bar" :class="{ active: currentStep >= 1 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 2 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 3 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 4 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 5 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 6 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 7 }"></div>
        <div class="progress-bar" :class="{ active: currentStep >= 8 }"></div>
      </div>
      <h3 class="text-white d-flex justify-content-center"> SETUP CORPORATE ACCOUNT</h3>

    </div>
    <div class="d-flex">
      
    <div class="content-wrapper">
    
      <div class="modal-body" v-if="currentStep == 5">
        <form >
          <br>
          <h5 class="text-white">Registrant Personal Information - Authorized account holder</h5>
          <fieldset>
            <div class="box2">
              <label for="bType">Title:<strong>*</strong></label>
              <select id="bType" v-model="init.profile.title" class="form-control">
                <option value="" disabled>Select Title</option>
                <option value="Mr">Mr.</option>
                <option value="Mr">Mrs.</option>
                <option value="Ms">Ms.</option>
              </select>
              </div>
            <div class="box2 mobile-container">
              <label for="loginId">Mobile Number:<strong>*</strong></label>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                 
                </div>
                <input type="tel" class="form-control" v-model="init.profile.homePhone" @keypress="isNumber($event)" >
              </div>
             
            </div>

          </fieldset>
          <fieldset>
            <div class="box">
              <label for="business">First name:<strong>*</strong></label>
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.firstName">
            </div>
            <div class="box">
              <label for="business">Middle name:<strong>*</strong></label>
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.middleName">
            </div>
              <div class="box">
                <label for="business">Last name:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.lastName">
              </div>
          </fieldset>
          <fieldset>
            <h5 class="legend">Birthdate:</h5>
            <div class="box">
              <div class="form-control">
                {{init.profile.birthDate}}
              </div>
             
            </div>
           
          </fieldset>
         


          <fieldset>
            <h5 class="legend">Complete Address:</h5>
            <div class="box">
              <label for="address">Street:<strong>*</strong></label>
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.presentAddress.numberStreet">
            </div>
            <div class="box">
              <label for="address">City:<strong>*</strong></label>
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.presentAddress.cityTown">
            </div>
            <div class="box">
              <label for="address">Province:<strong>*</strong></label>
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.presentAddress.provState">
            </div>
          </fieldset>

          <fieldset>
            <div class="box2 mobile-container">
              <label for="business">Country:<strong>*</strong></label>
              <div class="input-group mb-3">
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.presentAddress.country"  >
              </div>
            
            </div>
            <div class="box2">
              <label for="address">Zipcode:<strong>*</strong></label>
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.presentAddress.postalCode">
            </div>

          </fieldset>
          
          <hr>

          <fieldset>
            <h5 class="legend">Extended Information:</h5>
            <div class="box">
              <label for="address">Email address:<strong>*</strong></label>
              <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.emailAddress" placeholder="email@email.com">
            </div>
            
              <div class="box">
                <label for="marital-status">Marital Status:<strong>*</strong></label>
                <input v-model="init.profile.maritalStatus" class="form-control"/>
                </div>
                <div class="box">
                  <label for="bType">ID number:<strong>*</strong></label>
                  <input id="bType" v-model="init.profile.identityNumber" class="form-control">
                </div>
              </fieldset>
              <fieldset>
              <div class="box mobile-container">
                <label for="business">Nationality:<strong>*</strong></label>
                <div class="input-group mb-3">
                  <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.nationality" >
                </div>
               
              </div>
              <div class="box">
                <label for="address">Place of Birth:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.placeOfBirth">
              </div>
              <div class="box">
                <label for="address">Mother's Maiden Name:<strong>*</strong></label>
                <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.mothersMaidenName">
              </div>

          </fieldset>


         <fieldset>
          <div class="box">
            <label for="address">Nature of Work:<strong>*</strong></label>
            <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.natureOfWork">
          </div>
          <div class="box">
            <label for="address">Designation:<strong>*</strong></label>
            <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.occupation">
          </div>
         
          <div class="box">
            <label for="address"># of Shares:<strong>*</strong></label>
            <input type="text" class="form-control" id="formGroupExampleInput" v-model="init.profile.noOfShares" >
          </div>

         </fieldset>
         <fieldset>
          <div class="box2">
            <label for="source-funds">Source of Funds:<strong>*</strong></label>
            <select id="source-funds" v-model="init.profile.sourceOfFunds" class="form-control">
              <option value="">Select</option>
              <option value="selfEmployed">self-employed</option>
              <option value="salary">salary</option>
              <option value="investment">investment</option>
            </select>
          </div>
     
          <div class="box2">
            <label for="address">Gross Monthly income:<strong>*</strong></label>
            <select id="bType" v-model="init.profile.grossMonthlyIncome" class="form-control">
              <option value="">Select</option>
              <option value="50000">Php0 - 50,000</option>
              <option value="100000">Php50,001 - 100,000</option>
              <option value="500000">Php100,001 - 500,000</option>
              <option value="500001">Php500,000 +</option>
            </select>
          </div>

         </fieldset>
           
            <div class="form-group ">
              <p class="text-white" style="color: red;">{{errorMsg}}</p>
              
            </div>
        </form>
        <button v-on:click="goToVerification" class="btn btn-main" id="loginBtn" >BACK</button>
      </div>
      <hr>
      
    </div>
  </div>
  <Upload v-if="showUpload" @close="openUpload"></Upload>
     <LogoutModal @close="openLogout" v-if="showLogoutModal"/>
     <ChangePin @close="openChangePin" v-if="showChangePin"/>
     <view-id v-if="showDetails" @close="viewId()" :poidItem="poidItem" :poidKey="poidKey"></view-id>
</div>
</template>

<script>
  import Upload from '@/components/modals/Upload.vue'
  import ViewId from '@/components/modals/ViewId.vue'
  import LogoutModal from '@/components/modals/LogoutModal.vue'
  import ChangePin from '@/components/modals/ChangePin.vue'
  import moment from 'moment'
  import viewHeader from '../../views/viewHeader.vue'

  export default {
    name: 'AccountComponent',
    components: {
      Upload,
      ViewId,
      LogoutModal,
      ChangePin,
      viewHeader
    },
    computed: {
      init() {
        return this.$store.state.init;
      },
    },
    data: function() {
      return { 
        showChangePin: false,
        currentStep: 5,
        errorMsg:'',
        poidDesc:'',
        approvalStatus:'',
        poid:[],
        poidItem:'',
        showDetails: false,
        showUpload: false,
        poidData:'',
        poidKey:'',
        uploaded: false,
        showLogoutModal: false,
        hasTradingName: false,
        errors: {
          profile: {
            msg: "",
            show: false,
           
          }
        },
        
    
       
      }
    },
    computed:{
      
       init() {
        	return this.$store.state.init;
    	},
    
      show() {
        return this.$store.state.show;
      },

    },
    methods: {
      formatDate: function(date) {
              return moment(date).format('MM/DD/yyyy');
            },
     
      goToVerification:function(){
        window.location.href = "/#/verification";
      },
     
     
      sessionCheck() {
       
          const options = {
                method: 'POST',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/sessioncheck',
                }
          this.axios(options)
          .then((response) => {
           this.init.hasSession = true;
           this.getProfile();
          }).catch((err) => {
            this.init.hasSession = false;
                
                // if(err.response.data.msgCode == '00001') {
                //   window.location.href = "/#/";
                // }  
  
               
  
              })
      },


      getProfile() {
        const options = {
                method: 'GET',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'blox-authz': localStorage.getItem("tokenId") 
                }, 
              
                url: '/cx/profile',
                }
                this.axios(options)
                .then((response) => {
                this.init.profile = response.data;
                console.log(response.data)
                if(response.data.presentAddress != null && response.data.presentAddress != undefined ){
                  this.init.profile.presentAddress.numberStreet = response.data.presentAddress.numberStreet
                  this.init.profile.presentAddress.provState = response.data.presentAddress.provState
                  this.init.profile.presentAddress.cityTown = response.data.presentAddress.cityTown
                  this.init.profile.presentAddress.country = response.data.presentAddress.country
                  this.init.profile.presentAddress.postalCode = response.data.presentAddress.postalCode
                }
                this.init.corpDetails.corpInfo = response.data.corpDetails.corpInfo
                  this.init.corpDetails.corpOfficeAddress = response.data.corpDetails.corpOfficeAddress
                  this.init.corpDetails.tradingAddress = response.data.corpDetails.tradingAddress
                 
              
                }).catch((err) => {
                    this.init.hasSession = false;
                })
            },
    },
    mounted() {
    this.sessionCheck();
    },
    watch: {
     
    }
  }
</script>
<style scoped>
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
.form-check{
  width: 1rem;
  height: 1rem;
}
  .content-wrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center !important;
    max-width: 1000px;
    min-width: 350px !important;
    margin: 10px auto;
    background-image: linear-gradient(to bottom, #202538 0%, #10121C 97%) !important;
    border-radius: 10px;
    z-index: 2;
    /* max-height: calc(100vh - 110px);
    overflow-y: auto; */
    padding: 5px;
  }
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-color: #695536;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}

label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}

.form-check{
  width: 3rem;
  
}
p{
  color: white;
  padding-right: 2em;
}
   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 100%;
  border-radius: 10px !important;
  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    width: 250px;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 250px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}
  
  </style>